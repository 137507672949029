import { ElementKey, ProxyProp } from '@/types/component';
import { MergeProps, PartPartial } from '@/types/utils';
import { isIntrinsicElement } from '@/utils/isIntrinsicElement';
import { logger } from '@/utils/logger';
import { MergeCustomizer } from '@/utils/merge';
import { tw } from '@/utils/tw';
import { withNonHTMLChildren, withSafeInnerHTML } from 'lib/utils';
import { mergeWith, omit, omitBy, pickBy, upperFirst } from 'lodash-es';
import React from 'react';
import { TVReturnType, VariantProps } from 'tailwind-variants';

// @ts-expect-error: shortcut for inferred generics
type AnyTheme = TVReturnType;

type GenericFunctionComponentProps<Element extends ElementKey, Theme extends AnyTheme, Extras = never> = MergeProps<
  React.ComponentPropsWithoutRef<Element>,
  Extras
> &
  React.PropsWithChildren &
  VariantProps<Theme> &
  ProxyProp<Element>;

type HTMLElementFromKey<Element extends ElementKey> = Element extends keyof HTMLElementTagNameMap
  ? HTMLElementTagNameMap[Element]
  : never;

const mergeCustomizer: MergeCustomizer = (a, b, key) => {
  if (['className', 'class'].includes(key!)) {
    return tw.merge(a, b);
  }

  if (Array.isArray(b)) {
    return b;
  }
};

export interface GenericSlotFunction<Element extends ElementKey, Theme extends AnyTheme> {
  <Extras>(
    props: GenericFunctionComponentProps<Element, Theme, Extras> & React.RefAttributes<HTMLElementFromKey<Element>>,
  ): React.ReactNode;
  displayName?: React.FunctionComponent['displayName'];
}

export type GenericSlotProps<Element extends ElementKey, Theme extends AnyTheme> = {
  theme: Theme;
  context?: React.Context<any>;
  slot?: keyof Theme['slots'] | keyof Theme['extend']['slots'];
  render?: (props: { element: JSX.Element }) => React.ReactNode;
  debug?: boolean;
} & Required<ProxyProp<Element>>;

export type GenericSlot = <Element extends ElementKey, Theme extends AnyTheme>(
  props: GenericSlotProps<Element, Theme>,
) => GenericSlotFunction<Element, Theme>;

export const GenericSlot: GenericSlot = ({ as, theme, slot, context, render, debug }) => {
  // eslint-disable-next-line react/display-name
  const Slot: ReturnType<GenericSlot> = React.forwardRef(({ children, options, ...props }, ref) => {
    const Element = (props.as || as || 'div') as ElementKey;
    const isStringElement = typeof Element === 'string';
    const isCustomElement = isStringElement && !isIntrinsicElement(Element);

    // Fix for production build when Element name is minified and doesn't start with 'Standalone'
    // find different way to distinguish if element is standalone
    const isStandalone =
      Boolean((Element as any).name?.startsWith('Standalone')) || (!isStringElement && !isCustomElement);

    const Context = context || React.createContext({});

    const resolvedTheme = props.theme || theme;

    const contextValue = React.useContext(Context);
    const optionsWithContext = { ...contextValue, ...contextValue[`$${String(slot)}`], ...options };
    const variantKeys = [...(resolvedTheme?.variantKeys || [])];

    const isProviderValue = (value: any, key: string) => key.startsWith('$') || variantKeys.includes(key);

    const resolvedOptions = omitBy(optionsWithContext, isProviderValue);
    const resolvedVariant = pickBy(optionsWithContext, isProviderValue);

    const resolvedClass = isCustomElement ? 'class' : 'className';
    const resolvedStyles = (resolvedTheme?.()?.[slot || 'base'] || resolvedTheme)?.(resolvedVariant);
    const resolvedProps = mergeWith(
      isStandalone
        ? { theme: resolvedTheme, options: resolvedVariant }
        : resolvedStyles
          ? { [resolvedClass]: resolvedStyles }
          : {},
      resolvedOptions,
      omit(props, ['as', 'theme']),
      mergeCustomizer,
    );

    let element: React.ReactNode = (
      <Element {...withSafeInnerHTML(children)} {...resolvedProps} ref={ref}>
        {withNonHTMLChildren(children)}
      </Element>
    );

    if (render) {
      element = render({ element });
    }

    if (!slot || slot === 'base') {
      element = <Context.Provider value={resolvedVariant}>{element}</Context.Provider>;
    }

    if (debug) {
      logger.debug({
        GenericSlot: { as, theme, slot, context, render, debug },
        Slot: { children, options, props, ref },
        resolved: { resolvedTheme, resolvedOptions, resolvedVariant, resolvedClass, resolvedStyles, resolvedProps },
        is: { isStandalone, isStringElement, isCustomElement },
        Element,
        Context,
        contextValue,
        optionsWithContext,
        variantKeys,
      });
    }

    return element;
  });

  if (!Slot.displayName) {
    Slot.displayName = upperFirst(slot?.toString()) || 'Base';
  }

  return Slot;
};

export type GenericSlotFactoryProps<Theme extends AnyTheme> = {
  theme: Theme;
  context?: React.Context<any>;
  debug?: boolean;
};

type Defined<A, B> = A extends undefined ? B : A;

export const GenericSlotFactory = <FactoryTheme extends AnyTheme>(
  factoryProps: GenericSlotFactoryProps<FactoryTheme>,
) => {
  const context = factoryProps?.context ?? React.createContext({});

  const slot = <Element extends ElementKey, Theme extends AnyTheme = undefined>(
    props: PartPartial<GenericSlotProps<Element, Defined<Theme, FactoryTheme>>, 'theme'>,
  ) =>
    GenericSlot<Element, Defined<Theme, FactoryTheme>>({
      ...factoryProps,
      context,
      ...props,
    });

  return slot;
};
