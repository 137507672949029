/* THIS FILE IS GENERATED ON RUNTIME. DO NOT EDIT */

import { ComponentClassNameFactory } from 'lib/contexts/ComponentClassNameFactory';
import getPropStylesFactory from 'lib/utils/getPropStylesFactory';
import getComponentStylesFactory from 'lib/utils/getComponentStylesFactory';
import getComponentThemeFactory from 'lib/utils/getComponentThemeFactory';
import make_styler from '../index';

export const styles = {
  "tab_colors_default": "bg-none hover:bg-white/80 data-[active]:bg-white data-[active]:shadow-[inset_0_0_3px_0_rgba(0,0,0,0.4)] data-[locked]:opacity-30",
  "tab_size_default": "px-4 py-2 gap-0.5",
  "tab_variant_default": "flex flex-row items-center rounded-md transition-colors data-[locked]:pointer-events-none",
  "colors_default": "bg-yellow-sea-400",
  "size_default": "p-1 gap-1 rounded-md",
  "variant_default": "inline-flex flex-row items-center justify-center mx-auto",
  "tab_size_small": "text-headline-xs py-1.5 px-2 !leading-none rounded-md",
  "tab_size_large": "text-headline-sm py-3 px-6 data-[active]:font-bold rounded-lg",
  "tab_colors_alternative": "bg-none hover:bg-yellow-sea-400/80 data-[active]:bg-yellow-sea-400 data-[active]:shadow-[inset_0_0_3px_0_rgba(0,0,0,0.4)] data-[locked]:opacity-30",
  "colors_alternative": "bg-white",
  "size_small": "p-0.5 gap-0.5 rounded-lg",
  "size_large": "p-1 gap-1 rounded-xl"
};

const c = make_styler(styles);

export const propClassName = getPropStylesFactory(c, styles, "Tabs");

export const componentClassName = getComponentStylesFactory(propClassName);

export const componentTheme = getComponentThemeFactory(styles);

export const {
  ClassNameContext,
  ClassNameProvider,
  useClassName,
  useTheme,
} = ComponentClassNameFactory(componentClassName, styles);
  
export default c;